@import '../../../../assets/css/colors.scss';

.theCol1{
    .react-datepicker-wrapper{
        width: 100%;
    }
    .dobInputDesign{
        width: 100% !important;
        height: 60px;
        border: none;
        background-color: #f4f4f4;
        color: #262262 !important;
        padding: 10px;
        border-bottom: 1px solid #5a5a5a;
        
        &:focus {
            border: none;
            border-bottom: 2px solid #262262;
            outline: none;
        }
        
    }

    .calImg{
        position: absolute;
        right: 8%;
        top: 45%;
    }

    .school-picker{
        height: 60px;
        .css-1f43avz-a11yText-A11yText{
            height: 60px;
            border-radius: 0px;
            color: #262262;
            background-color: #f4f4f4;
        }
        .css-yk16xz-control{
            height: 60px;
            border-radius: 0px;
            color: #262262;
            background-color: #f4f4f4;
    
        }
        .css-1pahdxg-control{
            background-color: #f4f4f4;
            height: 60px;
            border-radius: 0px;
            border-color: #262262;
            color: #262262;
        }

        @media (max-width: 768px) {
        margin-top: 10px;
            
        }
    }
}
.medical_desc{
    color: #262262;
}
.theCol1.error{

    position: relative;


    label{
        color: $red1 !important;
    }

    .MuiFilledInput-underline:after{
        border-bottom-color: $red1 !important;
    }
    #error{
        color: $red1 !important;
        
    }
    .dobInputDesign{
        width: 100%;
        height: 60px;
        border: none;
        background-color: #f4f4f4;
        color: #262262 !important;
        padding: 10px;
        border-bottom: 1px solid #5a5a5a;
        
        &:focus {
            border: none;
            border-bottom: 2px solid #262262;
            outline: none;
        }
        
    }

    .calImg{
        position: absolute;
        right: 8%;
        top: 45%;
    }

    .school-picker{
        height: 60px;
        .css-1f43avz-a11yText-A11yText{
            height: 60px;
            border-radius: 0px;
            color: $red1;
            background-color: #f4f4f4;
        }
        .css-yk16xz-control{
            height: 60px;
            border-radius: 0px;
            color: $red1;
            background-color: #f4f4f4;
    
        }
        .css-1pahdxg-control{
            background-color: #f4f4f4;
            height: 60px;
            border-radius: 0px;
            border-color: $red1;
            color: $red1;
            box-shadow: none;
        }
    }

}
.child_form_event{
    width: 100%;

    @media (max-width: 768px) {
        width: 100%;
    }
}
.conditionRow{
    .Component-textField-1{
        label{
                z-index: -1 !important;
            }
        }
    .Component-textField-2{
        label{
                z-index: -1 !important;
            }
        }
    .Component-textField-3{
        label{
                z-index: -1 !important;
            }
        }
    .Component-textField-4{
        label{
                z-index: -1 !important;
            }
        }
    .Component-textField-5{
        label{
                z-index: -1 !important;
            }
        }
}

