@import '../../../assets/css/colors.scss';
.Overlay {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;

}
.Cart_cursor-not-allowed{
	cursor: not-allowed !important;
}
.cart_EmptyMessage{
	display:flex;
	justify-content: center;
	align-items: center;
}

@keyframes CartOpening {
	from {
		right: -900px;
		overflow: hidden;
	}
	to {
		right: 0;
		overflow: auto;
	}
}
.centerR {
	padding-left: 1rem;
	float: right;
	width: fit-content;
}
.centerL {
	float: left;
	width: fit-content;
}
.cart_popup_container {
	height: 100%;
	overflow-y: scroll;
	padding: 38px 3rem 5rem;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}
.cart_popup_container::-webkit-scrollbar {
    display: none; 
}
.cart_quantity_div{
	border-top: 1px solid $blue1;
	width: 100%;
}
.cart_popup {
	height: 100%;
	width: 530px;
	z-index: 999;
	right: 0;
	transform: translateX(530px);
	transition: all ease 0.5s;
	position: fixed;
	animation-name: CartOpening;
	animation-duration: 350ms;
	transition:ease 350ms;
}
.cart_popup.active {
	transform: translateX(0%);
	transition: all ease 0.5s;
}
.items{
	display: flex;
	flex-direction: row;
}
.small_wilson_img img {
	width: 170px;
	height: 170px;
	object-fit: cover;
}
.cart_item_img {
	align-items: center;
}
.cart_item_img img {
	width: 100%;
	height: 230px;
	object-fit: cover;
}
.order_now {
	margin-left: 2rem !important;
}
.item_name{
	color: #ffffff;
	font-weight: bold;
	font-size: 15pt;	
}
.item_price{
	color: $red1;
	font-weight: bold;
	font-size: 15pt;	
}
.item_size_title{
	color: #ffffff;
	font-weight: bold;
	font-size: 15pt;
}
.item_size{
	color: #ffffff;
	font-weight: normal;
	font-size: 14pt;
}
.details{
	padding-top: 10px;
	padding-bottom: 10px;
}
.item-name{
	height: 50px;
	overflow-y: hidden;
	margin: 5px 0;
}
.white {
	color: $white1;
}
.text-upper {
	text-transform: uppercase;
}
.blue-bg {
	background: $blue3;
}
.ser {
	width: 66%;
}
.red {
	color: $red2;
}
.red-bg {
	background: $red1;
}
.cart_quantity_add_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	min-width: 80px;
	width: 40%;
	height: 45px;
	color: $white2;
	border: 1px solid $blue1;
}
.cart_quantity_button_minus {
	width: 25px;
	color: $white2;
	height: 100%;
	background: none;
	border: none;
	border-right: 1px solid $blue1;
	cursor: pointer;
}
.cart_quantity_button_plus {
	width: 25px;
	color: $white2;
	height: 100%;
	background: none;
	border: none;
	border-left: 1px solid $blue1;
	cursor: pointer;
}
.cart_quantity_button_minus:focus,
.cart_quantity_button_plus:focus {
	outline: none;
}
.button_diff {
	border: 1px solid $blue1;
	width: 114px;
	height: 40px;
	line-height: 40px;
	text-align: center;
}
.checkout_bottom {
	background: $blue2;
	bottom: 0;
}
.next_button {
	height: 50px !important;
	line-height: 50px !important;
}
.next_button > a,
.next_button > a:hover,
.next_button > a:active {
	text-decoration: none !important;
	color: $white2 !important;
}
.vertical-align-baseline {
	vertical-align: baseline;
}
.font-37 {
	font-size: 37px;
}
.borders {
	border-top: 1px solid $blue1;
	border-bottom: 1px solid $blue1;
	padding-top: 22px;
	padding-bottom: 19.5px;
}

.cart_item{
	border-top: 1px solid $blue1;
	border-bottom: 1px solid $blue1;
	margin-bottom: 10px;
	background-color: $blue3;
	width: 100%;
	height: 100%;
}
.line {
	color: $blue2;
	background: $blue2;
}
.remove_from_cart {
	display: flex;
	align-items: center;
}

.checkout_button {
	font-family: 'colab-bold';
	padding-right: 30px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	display: flex;
	flex-direction: row;
	background: $red2;
	justify-content: center;
	color:$white2;
}
.checkout_button > a,
.checkout_button > a:hover,
.checkout_button > a:active {
	text-decoration: none !important;
	color: $white2 !important;
}
.pointer {
	cursor: pointer;
}
@media (max-width: 853px) {
	.cart_item_img img {
		width: 100%;
		height: 240px;
		object-fit: cover;
	}
}
@media (max-width: 768px) {
	.small_wilson_img img {
		width: 270px;
		height: 200px;
		object-fit: fill;
	}
	.item_name{
		width: fit-content;	
	}
	.order_now {
		margin-left: 0px !important;
	}
	.cart_popup .pt-5 {
		padding: 7rem 25px 2rem !important;
	}
	.cart_popup .mr-5 {
		margin-right: 0rem !important;
	}
	.cart_popup .mb-4 {
		margin-bottom: 1rem !important;
	}
	.cart_popup .mt-5 {
		margin-top: 1rem !important;
	}
	.cart_popup .pb-4 {
		padding-bottom: 0.5rem !important;
	}
	.cart_popup .pr-4 {
		padding-right: 1.5rem !important;
	}
	.cart_popup {
		width: 100%;
		transform: translateX(100%);
	}
	.cart_item_img img {
		width: 100%;
		height: 100%;
		max-height: 250px;
		justify-content: center;
		object-fit: cover;
	}
	.details{
		padding: 1rem;
		width: 100%;
	}
	.item-name{
		overflow: none ;
		height: 100%;
	}
}
@media (max-width: 470px) {
	.items{
		display: flex;
		flex-direction: column;
	}
	.centerR{
		padding: 15px 5px;
	}
}