$white1:#FAFAFA;
$white2:#FFFFFF;
$red1:#D91C24;
$red2:#BF2229;
$grey1:#D6D5D9;
$grey2:#E6E6E6;
$grey3:#707070;
$grey4:#F2F2F2;
$blue1:#79769F;
$blue2:#514C91;
$blue3:#262262;
$blue4:#3F3B74;