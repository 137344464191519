@import '../../../assets/css/colors.scss';

.modal_heading{
  display: flex;
  flex-direction: row;
  align-items: baseline;

}

.paper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 80%;
  background-color: white;
  border: 1px solid #514C91;
  box-shadow: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.paper h1{
  color: #262262;
  font-weight: bold;
}

.paper h4{
  color: #262262;
  font-weight: normal;
}

.close_icon{
  width: 50px;
  height: 50px;
}

.error{
  color: $red1;
}


@media (max-width: 900px){
  .paper {
    position: absolute;
    width: 90%;
    height: 75%;
    background-color: #f5f5f5;
    border: 1px solid #514C91;
    box-shadow: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .paper h1{
    color: #262262;
    font-size: x-large;
  }

  .paper h4{
    color: #262262;
    font-weight: normal;
  }
}