@import "../../../../assets/css/colors.scss";

.eventBookingChild_Container{
    position: relative;
    padding: 0 0 4rem;
    margin-bottom:4rem;
}
.eventBookingChild_Container::after{
    content: "";
    width: 100%;
    height:1px;
    background-color: $grey2;
    position: absolute;
    bottom:0;
    left:0;
}
.eventBookingChild_Title{
    color:$blue3;
    margin-bottom:4rem;
}
.eventBookingChild_FormContainer{
    width: 80%;
}
.eventBookingChild_UploadPhoto{
    color:$blue3;
    cursor: pointer;
    margin-bottom: 3rem;
}
.eventBookingChild_Input{
    width: 100%;
    padding: 1rem !important;
    background-color: $grey4;
    border: 1px solid $grey4;
    margin:0.5rem 0 2rem;
    color:$blue3;
    font-family: 'colabregular';
}
.eventBookingChild_Input:active, .eventBookingChild_Input:focus{
    border-bottom: 1px solid $red1;
    outline: none;
    transition: ease 400ms;
}
.eventBookingChild_Input::placeholder{
    color: $blue1;;
}
.eventBookingChild_Input + label{
    opacity: 0%;
    color:$blue1;
    visibility: hidden;
}
.eventBookingChild_Input:focus + label{
    visibility: visible;
    opacity: 100%;
    transition:ease 500ms;
}
.eventBookingChild_SelectDateTitle{
    margin-top:2rem;
    color:$blue3;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    @media(max-width:480px){
        margin-top: 4rem;
    }
}

.same_as_first{
    cursor: pointer;
}
.week_container{
    @media (max-width: 710px) {
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        
    }
}
.eventBookingChild_Date{
    padding:0.5rem 3rem 0.5rem 0.5rem ;
    background-color: $grey4;
    color: $blue1;
    margin: 0.5rem;
    cursor: pointer;
    @media (max-width: 386px){
        padding: 0.5rem 20px;
    }
    @media (max-width:330px){
        padding: 0.5rem 10px;
    }
}
.eventBookingChild_RemoveButton{
    text-align: end;
    color:$red1;
    cursor: pointer;
}

.locationInputDesign {
	width: 100%;
	height: 60px;
	border: none;
	background-color: #f4f4f4;
	color: #262262 !important;
	padding: 10px;
	border-bottom: 1px solid #5a5a5a;

	&:focus {
		border: none;
		border-bottom: 2px solid #262262;
		outline: none;
	}
}

.soldOut
{
    display:flex;
    color:red
}