@import "../../../assets/css/colors.scss";

/* Text Input */
.textInput .MuiFilledInput-input {
  padding: 1rem;
  color: $blue3;
  font-family: "colabregular";
}
.textInput .MuiFilledInput-multiline {
  padding: 0;
}
.textInput .MuiFilledInput-root {
  border-radius: 0;
}
.textInputContainer {
  /* margin: 0.5rem; */
  margin: 0.5rem 0;
}
.textInputContainer .MuiFormHelperText-root {
  font-family: "colabregular";
}
.textInputContainer .textInputLabel,
.selectInputContainer .textInputLabel {
  color: $blue1;
  font-family: "colabregular";
}
/* Checkbox */
.checkboxContainer .MuiFormControlLabel-root {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* margin: 0; */
}
.checkboxContainer .MuiFormControlLabel-label {
  font-family: "colabregular";
}
.selectInputContainer {
  margin: 0.5rem 0;
}
.selectInputContainer .MuiSelect-root {
  padding: 1rem;
  color: $blue3;
  font-family: "colabregular";
}
.selectInputContainer svg {
  width: 10px;
  margin-right: 10px;
}
// .selectInputContainer .img-select {
// }
// Date Picker
.react-datepicker-wrapper {
  width: 100%;
}
.autocompleteSelect_school .css-1uccc91-singleValue {
  color: $blue3;
}
.autocompleteSelect_school > div:first-of-type {
  border: 0;
  color: $blue3;
  font-weight: 500;
  padding-inline-start: 15px;
  background-color: rgba(0, 0, 0, 0.09);
  height: 50px;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.autocompleteSelect_children .css-1uccc91-singleValue {
  color: $blue3;
}
.autocompleteSelect_children > div:first-of-type {
  border: 0;
  color: $blue3;
  font-weight: 500;
  padding-inline-start: 15px;
  background-color: rgba(0, 0, 0, 0.09);
  height: auto;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.redButton.MuiButton-root {
  color: #fff;
  background-color: #D91C24 ;
  border: 1px solid #D91C24;
  border-radius: 0;
  transition: ease all 400ms;
  margin: .5rem 0;
  text-transform: uppercase;
  font-family: 'colab-bold';
}
.redButton.MuiButton-root:hover{
  background-color: #fff ;
  color: #D91C24;
  transition: ease all 400ms ;
}

.blueButton.MuiButton-root {
  color: #fff;
  background-color: $blue3 ;
  border: 1px solid $blue3;
  border-radius: 0;
  transition: ease all 400ms;
  margin: .5rem 0;
  text-transform: uppercase;
  font-family: 'colab-bold';
}
.blueButton.MuiButton-root:hover{
  background-color: #fff ;
  color: $blue3;
  transition: ease all 400ms ;
}

.whiteButton.MuiButton-root {
  color: $blue3;
  background-color: #fff ;
  border: 1px solid $blue3;
  border-radius: 0;
  transition: ease all 400ms;
  margin: .5rem 0;
  text-transform: uppercase;
  font-family: 'colab-bold';
}
.whiteButton.MuiButton-root:hover{
  background-color: #fff ;
  color: $blue3;
  transition: ease all 400ms ;
}

.MuiButton-root.Mui-disabled {
  background-color: rgb(194, 194, 194) ;
  color: #fff !important;
  border: 1px solid rgb(194, 194, 194) ;
}
