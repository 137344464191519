@import "../../../../assets/css/colors.scss";

.eventBooking-auto-address-textfield{
    width: 100% !important;
    height: 60px !important;
    background: #F4F4F4;
    border: none !important;
    position: relative !important;

    & div{
        height: 100%;
        border: none !important;
        outline: none !important;

        & fieldset{
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #8e8e8e;
        }
    }

    .address-icon{
        color: #79769F;
        font-size: 24px;
    }

    .address-auto-container {
        color: #79769F;
        background: #fff;
        padding: 10px;
        height: 175px;
        font-size: 13px;
        width: 100%;
        position: relative;

        & .MuiPaper-root{
            position: absolute !important;
            z-index: 99;
        }
    };

}
.acountParent-link{
    margin: 2rem 0;
	color: $blue1;
	cursor: pointer;
    font-size: 18px;
}
.eventBookingParent_Container{
    position: relative;
    padding: 0 0 4rem;
    margin-bottom:4rem;
    @media (max-width:480px){
        margin-bottom: 1rem;
        padding: 0 0 1rem;
    }
}
.eventBookingParent_Container::after{
    content: "";
    width: 100%;
    height:1px;
    background-color: $grey2;
    position: absolute;
    bottom:0;
    left:0;
}
.eventBookingParent_Title{
    color:$blue3;
    margin-bottom:4rem;
    @media(max-width:480px){
        margin-bottom: 1rem
        ;
    }
}
.eventBookingParent_FormContainer{
    width: 100%;

    @media (max-width: 768px){
        width: 100%;
    }
}
.eventBookingParent_UploadPhoto{
    color:$blue3;
}
.eventBookingParent_Input{
    width: 100%;
    padding: 1rem !important;
    background-color: $grey4;
    border: 1px solid $grey4;
    margin:0.5rem 0 2rem;
    color:$blue3;
    font-family: 'colabregular';
}
.eventBookingParent_Input:active, .eventBookingParent_Input:focus{
    border-bottom: 1px solid $red1;
    outline: none;
    transition: ease 400ms;
}
.eventBookingParent_Input::placeholder{
    color: $blue1;;
}
.eventBookingParent_Input + label{
    opacity: 0%;
    color:$blue1;
    visibility: hidden;
}

.eventBookingParent_Input:focus + label{
    visibility: visible;
    opacity: 100%;
    transition:ease 500ms;
}