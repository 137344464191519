/* @font-face {
	font-family: 'bold';
	src: url(../fonts/Chivo-Bold.woff);
}
@font-face {
	font-family: 'regular';
	src: url(../fonts/Chivo-Regular.woff);
}
@font-face {
	font-family: 'light';
	src: url(../fonts/Chivo-Light.woff);
} */
@font-face {
  font-family: "colab-bold";
  src: url(../fonts/ColabBol.otf);
  font-display: swap;
}
@font-face {
  font-family: "colabregular";
  src: url(../fonts/ColabReg.otf);
  font-display: swap;
}
@font-face {
  font-family: "colab-light";
  src: url(../fonts/ColabThi.otf);
  font-display: swap;
}
@font-face {
  font-family: "athletic";
  src: url(../fonts/Athletic.TTF);
  font-display: swap;
}
/* ::-webkit-credentials-auto-fill-button { //autocomplete off on mac
  visibility: hidden;
} */

a {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none !important;
}

.bold {
  font-family: "colab-bold";
}
.regular {
  font-family: "colabregular";
}
.light {
  font-family: "colab-light";
}
.athletic-font {
  font-family: "athletic";
}
.text-upper {
  text-transform: uppercase;
}
body,
html {
  /* height: 100% !important; */
  scroll-behavior: smooth;
}
body {
  font-family: "colabregular" !important;
  overflow-x: hidden;
}

.main-content {
  padding: 95px 0px;
  min-height: 80vh;
}
.main-title::before {
  padding-bottom: 5px;
  height: 5px;
  width: 88px;
  background-color: #d91c24;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.secondary-title::before {
  height: 5px;
  width: 88px;
  background-color: #d91c24;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
}
.bg-grey {
  background-color: #f2f2f2 !important;
}
.bg-blue {
  background-color: #262262 !important;
}
.blue {
  color: #262262 !important;
}
.red {
  color: #d91c24 !important;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-21 {
  font-size: 21px;
  line-height: 21px;
}
.font-25 {
  font-size: 25px;
  line-height: 25px;
}
.font-24 {
  font-size: 24px;
  line-height: 24px;
}
.font-33 {
  font-size: 33px;
}
.font-36 {
  font-size: 36px;
  line-height: 36px;
}
.font-39 {
  font-size: 39px;
}
.font-45 {
  font-size: 45px;
  line-height: 45px;
}
.font-48 {
  font-size: 48px;
  line-height: 48px;
}

.line-space-2 {
  line-height: 2rem !important;
  @media (max-width: 992px) {
    line-height: 1.75rem !important;
  }
}

@media (max-width: 1000px) {
  .main-content {
    padding-top: 0px !important;
  }
}
@media (max-width: 1480px) {
  .font-48 {
    font-size: 38px;
    line-height: 38px;
  }
  .font-39 {
    font-size: 30px;
  }

  .font-21 {
    font-size: 18px;
    line-height: 18px;
  }
}
@media (max-width: 1366px) {
}
@media (max-width: 1199px) {
  .font-24 {
    font-size: 20px;
    line-height: 20px;
  }
  .font-48 {
    font-size: 32px;
    line-height: 32px;
  }
  .font-18 {
    font-size: 16px;
  }
  .font-36 {
    font-size: 30px;
    line-height: 30px;
  }
  .pr-40 {
    padding-right: 30px;
  }
  .pl-40 {
    padding-left: 30px;
  }
  .pb-40 {
    padding-bottom: 30px;
  }
  .pt-40 {
    padding-top: 30px;
  }
  .mr-80 {
    margin-right: 40px;
  }
  .ml-80 {
    margin-left: 40px;
  }
  .font-21 {
    font-size: 19px;
    line-height: 19px;
  }

  .font-48 {
    font-size: 26px;
    line-height: 26px;
  }
  .pb-3 {
    padding-bottom: 10px !important;
  }
  .pt-3 {
    padding-top: 10px !important;
  }

  .font-39 {
    font-size: 25px;
  }
  .font-48 {
    font-size: 28px;
    line-height: 28px;
  }
  .pb-80 {
    padding-bottom: 60px;
  }
  .pt-80 {
    padding-top: 60px;
  }
}
@media (max-width: 1024px) {
  .pl-5 {
    padding-left: 1rem !important;
  }
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
  /* .main-content { */
  /* padding-top: 215px !important; */
  /* } */
  .font-14,
  .font-16 {
    font-size: 10px;
  }
  .font-36 {
    font-size: 25px;
    line-height: 25px;
  }
  .font-21 {
    font-size: 13px;
    line-height: 16px;
  }
  .font-39 {
    font-size: 20px;
  }

  .font-48 {
    font-size: 25px;
    line-height: 25px;
  }
}
@media (max-width: 600px) {
  .font-11 {
    position: static;
    padding: 10px 0;
  }
  /* .secondary-title::before {
			height: 3px;
			width: 88px;
			background-color: #d91c24;
			display: block;
			content: '';
			position: absolute;
			top: -7px;
			left: 0%;
		} */
  .main-title::before {
    height: 3px;
    width: 88px;
    background-color: #d91c24;
    display: block;
    content: "";
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 480px) {
  .font-15 {
    font-size: 13px;
  }
}
@media (max-width: 375px) {
}

@media (max-width: 320px) {
}

.Toastify__toast-body {
	font-family: "colabregular" !important;
	font-size: 16px;
}
